
import _fetch from "../utils/request";
// 学生护眼产品列表
export  function cateList(query) {
    return _fetch({
        url:'/v1/rls/cate/list',
        method:'get',
        params: query,
        needToken:true
    })
}
// 热门医院 在线医院v1/rls/hospital/list
export  function hospitalList(query) {
    return _fetch({
        url:'/v1/rls/hospital/list',
        method:'get',
        params: query,
        needToken:true
    })
}
// 在线医院  热门医院 详情 
export  function hospitalDetail(query) {
    return _fetch({
        url:'/v1/rls/hospital/detail',
        method:'get',
        params: query,
        needToken:true
    })
}
// 健康商城v1/rls/topic/list
export  function topicList(query) {
    return _fetch({
        url:'/v1/rls/topic/list',
        method:'get',
        params: query,
        needToken:true
    })
}
// 分类商品列表/搜索
export  function productList(query) {
    return _fetch({
        url:'/v1/rls/product/list',
        method:'get',
        params: query,
        needToken:true
    })
}
// 商品详情v1/rls/product/detail
export  function productDetail(query) {
    return _fetch({
        url:'/v1/rls/product/detail',
        method:'get',
        params: query,
        needToken:true
    })
}
// 文章搜索
export  function topicMore(query) {
    return _fetch({
        url:'/v1/rls/topic/more',
        method:'get',
        params: query,
        needToken:true
    })
}
// 文章详情v1/rls/topic/detail 
export  function topicDetail(query) {
    return _fetch({
        url:'/v1/rls/topic/detail',
        method:'get',
        params: query,
        needToken:true
    })
}
// 首页热门v1/rls/product/hot/list
export  function productHotList(query) {
    return _fetch({
        url:'/v1/rls/product/hot/list',
        method:'get',
        params: query,
        needToken:true
    })
}
// 护眼训练v1/rls/user/user/clock
export  function userClock(query) {
    return _fetch({
        url:'/v1/rls/user/clock',
        method:'get',
        params: query,
        needToken:true
    })
}
// 打卡训练v1/rls/user/clock/save
export  function userClockSave(data) {
    return _fetch({
        url:'/v1/rls/user/clock/save',
        method:'post',
        data,
        needToken:true
    })
}
// 咨询详情v1/rls/user/consultation/detail
export  function consultationDetail(query) {
    return _fetch({
        url:'/v1/rls/user/consultation/detail',
        method:'get',
        params: query,
        needToken:true
    })
}
// 回复留言v1/rls/user/consultation/reply
export  function consultationReply(data) {
    return _fetch({
        url:'/v1/rls/user/consultation/reply',
        method:'post',
        data,
        needToken:true
    })
}
// 获取上传链接
export  function qiniuUploadImage(query) {
    return _fetch({
        url:'/qiniu/upload-image',
        method:'get',
        params: query,
        needToken:true
    })
}
// 保存上传v1/rls/user/consultation/save
export  function consultationSave(data) {
    return _fetch({
        url:'/v1/rls/user/consultation/save',
        method:'post',
        data,
        needToken:true
    })
}
// 省市区v1/region/list
export  function regionList(query) {
    return _fetch({
        url:'/v1/region/list',
        method:'get',
        params: query,
        needToken:true
    })
}
// 首页搜索v1/rls/index/search
export  function search(query) {
    return _fetch({
        url:'/v1/rls/index/search',
        method:'get',
        params: query,
        needToken:true
    })
}
// 综合药物v1/rls/cate/z-list
export  function catezList(query) {
    return _fetch({
        url:'/v1/rls/cate/z-list',
        method:'get',
        params: query,
        needToken:true
    })
}
// 鉴权getSignPackage
export  function getSignPackage(query) {
    return _fetch({
        url:'/getSignPackage',
        method:'get',
        params: query,
        needToken:true
    })
}