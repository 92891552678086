<template>
  <div class="typeClassification">
    <div class="content">
      <div class="search">
        <van-search
          v-model="search_word"
          show-action
          shape="round"
          placeholder="请输入搜索关键词"
        >
          <template #action>
            <div @click="search" class="seachStyle">搜索</div>
          </template>
        </van-search>
      </div>
      <div class="hotRecommend">
        <div class="title">
          <span class="biu"></span>
          <div class="text">{{ $route.query.title }}</div>
        </div>
        <div class="itemContent">
          <div
            class="item"
            @click="detailInfo(item.id)"
            v-for="(item, index) in prdList"
            :key="index"
          >
            <img :src="item.main_imgs" class="goods" alt="" />
            <div class="goods-info">
              <div class="goods-title">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { productList } from '@/api/studentProduct'
export default {
  data () {
    return {
      search_word: '',
      prdList: [],
      typeId: null
    }
  },
  created () {
    this.typeId = this.$route.query.id
    this.getList(this.typeId)
  },
  watch: {
    search_word: {
      handler (val) {
        if (val == '') {
          this.getList(this.typeId)
        }
      }
    }
  },
  methods: {
    async getList (id) {
      const res = await productList({ id: id, page: 1 })
      if (res.data.code === 200) {
        this.prdList = res.data.data.list
      }
    },
    async search () {
      const res = await productList({
        id: this.typeId,
        search_word: this.search_word,
        page: 1
      })
      if (res.data.code === 200) {
        this.prdList = res.data.data.list
      }
    },
    detailInfo (id) {
      this.$router.push({
        path: '/goodsDetail',
        query: {
          id: id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.typeClassification {
  background: #f7f8f9;
  height: calc(200vh - 145px);
  .content {
    .hotRecommend {
      margin-top: 15px;
      .title {
        padding: 0 15px;
        color: #333333;
        font-size: 20px;
        font-weight: bold;
        border-radius: 2px;
        height: 20px;
        display: flex;
        align-items: center;
        .biu {
          width: 4px;
          height: 15px;
          background-color: #2163e9;
          border-radius: 2px;
        }
        .text {
          padding-left: 10px;
        }
      }
      .itemContent {
        margin-top: 10px;
        padding: 0 10px; // 剧中
        columns: 2;
        column-gap: 10px; // 列间距
        background-color: #f8f8f8;
        .item {
          position: relative;
          background-color: #fff;
          width: 172px;
          margin-bottom: 10px;
          break-inside: avoid;
          // border-radius: 10px;
          overflow: hidden;
          .goods {
            width: 100%;
          }
          .eyeIcon {
            position: absolute;
            top: 5px;
            left: 5px;
            background-color: #999;
            color: #fff;
            font-size: 10px;
            padding: 4px 6px;
            border-radius: 9px;
          }
          .goods-info {
            padding: 12px 10px 10px 10px;
            .goods-title {
              font-size: 14px;
              font-weight: 510;
            }
            .goods-user {
              margin-top: 15px;
              display: flex;
              justify-content: space-between;
              font-size: 12px;
              color: #bababa;
              .flex-user-style {
                .img-title {
                  width: 16px;
                  height: 16px;
                  border-radius: 50%;
                  border: 1px solid #ccc;
                  margin-right: 5px;
                }
              }
              .like {
                .good-style {
                  width: 12px;
                  margin-right: 3px;
                }
                .msg {
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }
  }
  ::v-deep .seachStyle {
    font-size: 13px;
    padding: 0 10px;
    border-radius: 18px;
    color: #fff !important;
    background-color: #2163e9 !important;
  }
  ::v-deep .van-search {
    background: none;
  }
  ::v-deep .van-search__content--round {
    background-color: #fff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  }
}
</style>
